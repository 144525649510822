import React from 'react';
import './copyright.css';
import { FaLinkedin, FaTwitter, FaGithub, FaEnvelope } from 'react-icons/fa';

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="footer__copyright">
      <div className="footer__copyright-content">
        <div className="footer__copyright-left">
          <p>© {currentYear} Raisence Solutions</p>
          <span className="footer__separator">|</span>
          <p>All Rights Reserved</p>
        </div>
        <div className="footer__copyright-right">
          <div className="footer__contact">
            <FaEnvelope className="footer__icon" />
            <a href="mailto:contact@raisence.com">contact@raisence.com</a>
          </div>
          <div className="footer__social-links">
          {/*}  <a href="https://linkedin.com/company/raisence" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin className="footer__icon" />
            </a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;