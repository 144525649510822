import React, { useState, useEffect } from 'react';
import './footer.css';

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    development: false,
    multimedia: false,
    automation: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // For demonstration, log form data to console
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.ratufa.io/c/ld.js?f=ggioz97i&n=n1.ratufa.io';
    script.id = 'ratufa_loader';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step into the future before others?</h1>
      </div>

      <form id="contact" className="gpt3__footer-form" onSubmit={handleSubmit}>
        <h2 className="formTitle">Contact Us</h2>
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <p className="form-group_text">Select the services you are interested in:</p>
          <label className="checkbox-label">
            <input type="checkbox" name="development" checked={formData.development} onChange={handleChange} />
            <span className="sp">Development</span>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" name="automation" checked={formData.automation} onChange={handleChange} />
            <span className="sp">Automation</span>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" name="multimedia" checked={formData.multimedia} onChange={handleChange} />
            <span className="sp">AI Chatbot</span>
          </label>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Footer;
