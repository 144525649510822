import React, { useState, useEffect } from 'react';

import { Footer, Possibility, WhatGPT3, Header, Copyright } from './containers';
import { CTA, Brand, Navbar, Card } from './components';

import './App.css';

const App = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {  // Adjust this value as needed
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <div className="gradient__bg">
        <a href="#contact">
          <div className="flying-image" />
          <div className={`fixed-image ${!isVisible ? 'hidden' : ''}`} />
        </a>
        <Navbar />
        <Header />
      </div>
      <Brand />
      <WhatGPT3 />
      <Card />
      <Possibility />
      <CTA />
      <Footer />
      <Copyright />
    </div>
  );
};

export default App;
