import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="we">
    <div className="gpt3__whatgpt3-feature">
      <Feature 
        title="Elevate Your Business with Digital Innovation" 
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Discover What We Can Build Together</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature 
        title="Web & Mobile Excellence" 
        text="Create stunning, responsive websites and powerful mobile apps that captivate your audience and drive engagement. Our solutions are built for performance, user experience, and business growth." 
      />
      <Feature 
        title="AI-Powered Innovation" 
        text="Harness the power of artificial intelligence with custom chatbots and automation solutions that streamline operations, enhance customer service, and unlock new possibilities." 
      />
      <Feature 
        title="Digital Growth Strategy" 
        text="Transform your digital presence with data-driven strategies, optimized user experiences, and scalable solutions that position your business for long-term success." 
      />
    </div>
  </div>
);

export default WhatGPT3;
