import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {  // Change to your preferred scroll threshold
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`gpt3__navbar ${isScrolled ? 'scrolled' : ''}`}>
      {isScrolled && <div className="fixed-image navbar-logo" />}
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo" />
        <div className="gpt3__navbar-links_container">
          <p><a href="#raisence">Home</a></p>
          <p><a href="#we">Who we are ?</a></p>
          <p><a href="#services">Services</a></p>
          {/*<p><a href="#portfolio">Portfolio</a></p>*/}
          <p><a href="#mission">Mission</a></p>
          <p id="bl"><a href="#contact">Contact</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><a href="#raisence">Home</a></p>
            <p><a href="#we">Who we are ?</a></p>
            <p><a href="#services">Services</a></p>
            <p><a href="#portfolio">Portfolio</a></p>
            <p><a href="#mission">Mission</a></p>
            <p id="bl"><a href="#contact">Contact</a></p>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
