import React from 'react';
import './possibility.css';
import devGif from '../../assets/dev.gif';
import autoGif from '../../assets/auto.gif';
import botGif from '../../assets/bot.gif';

const Possibility = () => (
  <>
    {/* Development Section */}
    <div className="gpt3__possibility section__padding" id="development">
      <div className="gpt3__possibility-content">
        <h1 className="gradient__text">
          Custom Web & Mobile<br />
          Applications For The<br />
          Modern Digital Age
        </h1>
        <p>
          Build powerful, scalable applications with our expertise:
          <br />• Full-Stack Web Development
          <br />• Native Mobile Apps
          <br />• Cross-Platform Solutions
          <br />• Progressive Web Apps (PWA)
        </p>
      </div>
      <div className="gpt3__possibility-image">
        <img src={devGif} alt="Development Animation" />
      </div>
    </div>

    {/* Chatbot Section */}
    <div className="gpt3__possibility section__padding" id="mission">
      <div className="gpt3__possibility-image">
        <img src={botGif} alt="AI Bot Animation" />
      </div>
      <div className="gpt3__possibility-content">
        <h1 className="gradient__text">
          Transform Your Customer Service<br />
          With Advanced AI<br />
          Chatbot Solutions
        </h1>
        <p>
          Elevate your business with our cutting-edge AI chatbots that provide:
          <br />• 24/7 Automated Customer Support
          <br />• Natural Language Understanding
          <br />• Seamless Integration
          <br />• Personalized User Experience
        </p>
      </div>
    </div>

    {/* Automation Section */}
    <div className="gpt3__possibility section__padding reverse" id="automation">
      <div className="gpt3__possibility-image">
        <img src={autoGif} alt="Automation Animation" />
      </div>
      <div className="gpt3__possibility-content">
        <h1 className="gradient__text">
          Streamline Your Business<br />
          With Intelligent<br />
          Workflow Automation
        </h1>
        <p>
          Optimize your operations with automated solutions:
          <br />• Business Process Automation
          <br />• Task Management Systems
          <br />• Custom Workflow Design
          <br />• Integration Services
        </p>
      </div>
    </div>
  </>
);

export default Possibility;
