import React from 'react';
import './card.css';
import p from '../../assets/p.png';
import d from '../../assets/d.png';
import s from '../../assets/s.png';
import c from '../../assets/c.png';

const Card = () => (
  <section id="services" className="services-section">
    <div className="services-container">
      <div className="row">
        <h2 className="section-heading">Our Services</h2>
      </div>
      <div className="row">
        <div className="column">
          <div className="card">
            <div className="icon-wrapper">
              <i className="fa">
                <img className="ex" src={p} width="60%" alt="Web Development" />
              </i>
            </div>
            <h3>Web & App Development</h3>
            <p>
              Custom websites and applications built with modern technologies for optimal performance and user experience
            </p>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="icon-wrapper">
              <i className="fa_s">
                <img className="ex" src={c} width="70%" alt="AI Chatbot" />
              </i>
            </div>
            <h3>AI Chatbot Development</h3>
            <p>
              Intelligent conversational AI solutions to automate customer support and enhance user engagement
            </p>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="icon-wrapper">
              <i className="fa">
                <img className="ex" src={s} width="70%" alt="Workflow Automation" />
              </i>
            </div>
            <h3>Workflow Automation</h3>
            <p><br></br>
              Streamline your business processes with custom automation solutions that boost efficiency and reduce manual tasks
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Card;
